<template>
  <div>
    <v-card>
      <v-card-title>
        <h2 class="text-center mb-5 mx-auto">
          Arcade Knockout Tournament
        </h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex col-md-6"
            style="flex-direction:column"
          >
            <div class="flex-grow-1">
              <v-card-title>
                <h3>Next tournament</h3>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <h4>
                      {{ next_start_date|moment("dddd MMM Do, h:mm a ") }}<small>({{ tz }})</small> ({{
                        next_start_date|moment("from", "now")
                      }})
                    </h4>
                    <h4>Weight Class: Middleweight Division</h4>

                    <br>
                    <strong>Prizes:</strong><br>
                    <br>
                    <p>
                      1st Place
                      <v-avatar
                        size="25"
                        class="mr-0 ml-1"
                      >
                        <v-img
                          src="@/assets/images/buy/KombatCoinSilver.png"
                          class="float-left mt-0"
                        ></v-img>
                      </v-avatar>
                      1,000,000 + Limited edition Gold NFT Fighter<br>
                    </p>
                    <p>
                      2nd place
                      <v-avatar
                        size="25"
                        class="mr-0"
                      >
                        <v-img
                          src="@/assets/images/buy/KombatCoinSilver.png"
                          class="float-left mt-0"
                        ></v-img>
                      </v-avatar>
                      500,000
                      + limited edition Silver NFT Fighter<br>
                    </p>
                    <p>
                      3rd place
                      <v-avatar
                        size="25"
                        class="mr-0 ml-1"
                      >
                        <v-img
                          src="@/assets/images/buy/KombatCoinSilver.png"
                          class="float-left mt-0"
                        ></v-img>
                      </v-avatar>
                      250,000
                      (x2 no playoffs)<br>
                    </p>
                    <small>Prizes are issued within 24 hours of event completion.</small>
                    <br>
                    <br>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex col-md-6"
            style="flex-direction:column"
          >
            <div class="flex-grow-1">
              <v-card-title>
                <h3>Rules</h3>
              </v-card-title>
              <v-card-text>
                <p>Competing teams are assigned to a cohort, each cohort has 8 teams.</p>
                <p>You can only use fighters from the same weight division.</p>
                <p>Each Arcade fight is the best of 3 rounds, winners progress.</p>
                <p>
                  Opponent fighters are A.I powered based on team/player attributes so
                  fights can start any time during the tournament window.
                </p>
              </v-card-text>
            </div>
          </v-col>
        </v-row>

        <div class="text-center mt-6">
          <router-link to="/fight-arcade-main">
            <v-btn
              outlined
              color="primary"
            >
              Arcade Practice
            </v-btn>
          </router-link>
        </div>
        <v-divider class="my-6"></v-divider>
        <h2>Your Tournament Draw</h2>
        <p>
          Next tournament starts: {{
            next_start_date|moment("dddd MMM Do, h:mm a z")
          }}<small>({{ tz }})</small>
        </p>
        <bracket :rounds="rounds">
          <template #player="{ player }">
            <v-avatar>
              <v-img :src="player.name.logo||'https://www.fantasykombat.com/android-chrome-192x192.png'" />
            </v-avatar>
            {{ player.name.name }}
          </template>
        </bracket>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Bracket from 'vue-tournament-bracket'
import dbFunctions from '@/db'

export default {
  components: {
    Bracket,
  },
  data() {
    return {
      team: '',
      next_start_date: this.$moment().startOf('week').add(12, 'day').add(22, 'hours'),
      next_opponent: {
        name: 'Marvin Vettori',
        logo: '/fighters/head-marvin-vettori.png',
      },
    }
  },
  computed: {
    rounds() {
      return [
        {
          // prelims
          games: [
            {

              player1: {id: '1', name: this.team},
              player2: {id: '65578a75fa7900e3', name: this.next_opponent},
            },
            {

              player1: {id: '5', name: ''},
              player2: {id: '8', name: ''},
            },
            {

              player1: {id: '1', name: ''},
              player2: {id: '4', name: ''},
            },
            {

              player1: {id: '5', name: ''},
              player2: {id: '8', name: ''},
            },
            {

              player1: {id: '1', name: ''},
              player2: {id: '4', name: ''},
            },
            {

              player1: {id: '5', name: ''},
              player2: {id: '8', name: ''},
            },
            {

              player1: {id: '1', name: ''},
              player2: {id: '4', name: ''},
            },
            {

              player1: {id: '5', name: ''},
              player2: {id: '8', name: ''},
            },

          ],
        },
        {
          // Qtr finals
          games: [
            {
              player1: {id: '1', name: ''},
              player2: {id: '4', name: ''},
            },
            {

              player1: {id: '5', name: ''},
              player2: {id: '8', name: ''},
            },
            {

              player1: {id: '1', name: ''},
              player2: {id: '4', name: ''},
            },
            {

              player1: {id: '5', name: ''},
              player2: {id: '8', name: ''},
            },

          ],
        },

        // Semi finals
        {
          games: [
            {

              player1: {id: '1', name: ''},
              player2: {id: '4', name: ''},
            },
            {

              player1: {id: '5', name: ''},
              player2: {id: '8', name: ''},
            },
          ],
        },

        // Final
        {
          games: [
            {
              player1: {id: '4', name: ''},
              player2: {id: '8', name: ''},
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.getTeam()
  },
  methods: {
    getTeam() {
      dbFunctions.getTeam(this.$store.state.user.uid)
        .then(teamData => {
          this.team = teamData
        })
    },
  },
}
</script>

<style lang="scss">
.vtb-item-players {
  border-radius: 8px;
  background-color: #312d4b;
  border: 2px solid #fff;
  color: #fff;
}

.vtb-item-child {
  margin-top: 5px;
  margin-bottom: 5px;
}

.vtb-player.vtb-player1 {
  border-radius: 2px !important;
  border-bottom: 2px solid #fff;
}
</style>
